import React, { useState, useEffect, useRef } from 'react';
import { Layout, Menu, ConfigProvider, Popover, Typography, Divider, Modal, Button } from 'antd';
import styles from './index.less';
import AbfMenuUtils from "@alife/abf-menu-utils";
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
import { micorInit } from "@ali/ysera-wei";
import { InfoCircleOutlined, CaretDownOutlined,ExportOutlined } from '@ant-design/icons';
const { Text } = Typography;
import _ from "lodash";
import './hsf2http';
import vmsService from "./vmsService";
import LoginMod from './loginMod';
const { Header, Content, Sider } = Layout;
import menuData from './menu';
import {InitAEM} from './aemTracker';
export interface IAdsLayoutProps {
  menus?: any;
  containorId?: string;
  pageVars?: any;
}

function findLinkInTree(tree, targetLink) {
  for (let node of tree) {
    // 检查当前节点是否有link属性且其值等于目标链接
    if (node.link && node.link === targetLink) {
      return true; // 找到了匹配的链接
    }

    // 如果当前节点有子节点，递归检查子树
    if (node.children && node.children.length > 0) {
      if (findLinkInTree(node.children, targetLink)) {
        return true; // 在子树中找到了匹配的链接
      }
    }
  }
  return false; // 整棵树中都没有找到匹配的链接
}

const AdsLayout: React.FunctionComponent<IAdsLayoutProps> = function (props: IAdsLayoutProps) {
  const { containorId = "app-root-container", } = props;
  const [menus, setMenus] = useState(menuData)
  const [currentkey, setCurrentkey] = useState('');//子menu||父menu key
  const [currentTopKey, setCurrentTopKey] = useState('');//顶部导航key
  const [account, setAccount] = useState<any>();//用户信息
  const [subMenuList, setSubMenuList] = useState<any>([]);
  const { handleNavChange, microJump} = AbfMenuUtils({});
  const [visible, setVisible] = useState(false);
  const cur_path = location.pathname;
  const subViewAdvertiserId = new URLSearchParams(window.location.search).get('subViewAdvertiserId');
  const [realSideMenu, setRealSideMenu] = useState([]);
  const [sideOpenKeys, setSideOpenKeys] = useState<any>([]);

  const microJumpWithLoginCheck = async (url) => {
    const data = await vmsService.loginOrRegist();
    const cur_path = location.pathname;
    if (!data?.success && (data?.msgCode == 'MOZI_NOT_LOGIN' || data?.msgCode == 'MOZI_ACCOUNT_DELETED')) {
      setAccount({});
      //没登录的时候跳home页面
      if (cur_path !== '/') {
        window.location.href = '/';
      }
    } else if (account.advertiserId == data?.result?.advertiserId) {
      // 登录了，且广告主id一致
      microJump(url)
    } else {
      // 登录了，且广告主id不一致
      window.location.href = '/';
    }
  }

  const adsAEM = useRef<any>(null);
  // url更新时，重新选择菜单
  const defaultSelect = (menuparams) => {
    if (!findLinkInTree(menuparams, location.pathname) && location.pathname !== '/' && location.pathname !== '/register') {
      if ((window as any)?.loginInfo?.role == 'worker_finance') {
        window.location.href = (window as any)?.loginInfo?.openNewBp ? '/605ca278' : '/3cb45b5a';
      } else if (location.pathname !== '/index') {
        window.location.href = '/index';
      }
      return false;
    }
    let item = handleNavChange(menuparams, undefined, location.pathname);
    if (item != null) {
      const sideData: any = findSideMenuData(menuparams, item.key);
      setSubMenuList(sideData?.children || []);
      setCurrentkey(item.key);
      setCurrentTopKey(sideData.key);//判断是几级菜单
    }
  };
  // 找到侧边栏数据，不过滤grey数据
  const findSideMenuData = (data, key) => {
    let sideData = {};
    data?.map(i => {
      if (i.key == key) {
        sideData = i;
      } else if (i.children?.length > 0) {
        const find = i.children?.find(j => j.key == key);
        if (find) {
          sideData = i;

        } else {
          i.children?.map(k => {
            const ff = k.children?.find(kk => kk.key == key);
            if (ff) {
              sideData = i;
            }
          })
        }
      }

    })
    return sideData
  }



  useEffect(() => {
    if (account?.role) {
      const menu = filterTreeByAuthCode(_.cloneDeep(menuData), account?.role);
      setMenus(menu)
      defaultSelect(menu);
    }
  }, [account?.role])
  useEffect(() => {
    adsAEM.current = new InitAEM();
    micorInit();
  }, []);
  useEffect(() => {
    window.addEventListener('popstate', () => {
      console.log("浏览器的返回键被按下");
      defaultSelect(menus);
    });
  }, [])

  
  // 点击顶部数据时
  const topMenuClick = async (topItem) => {

    if (!topItem.isBlank) {
      await microJumpWithLoginCheck(topItem.link)
    }else{
      window.open(topItem.link)
    }
    setCurrentTopKey(topItem.key)
    let k = (topItem.children && topItem.children[0]?.key) || topItem.key;
    setCurrentkey(k);
    setSubMenuList(topItem.children);
  };
  // 顶部dropdown点击
  const onTopClick = (item, items) => {
    leftMenuClick(item, () => {
      if (items) {
        setSubMenuList(items)
      }
    })

  }
  // 渲染顶部dropdown
  const renderMenuOnPop = (items, type, father) => {
    return <div style={{ display: 'flex' }}>{items?.filter(i=>{
      return i.grey? i.grey?.((window as any).loginInfo) : true
    }).map(i => {
      return <div className={styles.pop_menu}>
        {
          i.link ?
            <Text className={styles.menu_2} onClick={(e) => {
              e.stopPropagation();
              setCurrentTopKey(father.key);
              onTopClick(i, items)
              return false
            }}>{i.title}</Text>
            : <Text className={styles.menu_2}>{i.icon?.()}<span style={{ marginLeft: 4 }}>{i.title}</span></Text>
        }
        {
          i.children?.filter(i => !i.hidden).length > 0 &&
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
            {
              i.children?.filter(i => !i.hidden).map(j => <Text className={styles.menu_3} onClick={(e) => {
                e.stopPropagation();
                setCurrentTopKey(father.key);
                onTopClick(j, items)
                return false
              }
              }>{j.isNew?<img src='//img.alicdn.com/imgextra/i2/O1CN01aDVAxb25TsbMZ79Yg_!!6000000007528-55-tps-32-32.svg' style={{marginLeft: -24, width: 24, marginTop: -2}}/>: null}{j.title}{j.isBlank?<ExportOutlined style={{marginLeft: 4}}/>: null}</Text>)
            }
          </div>
        }
      </div>
    })}</div>
  }
  // 左侧菜单点击
  const leftMenuClick = async (leftItem, callback?: any) => {
    if(!leftItem?.isBlank){
      await microJumpWithLoginCheck(leftItem.link);
      setCurrentkey(leftItem.key);
      callback?.();
    }else{
      window.open(leftItem.link)
    }
  };

  // 获取左侧菜单
  const getSideMenu = (data) => {
    const dd = data?.map(i => {
      const { children, title, path, icon, key, greyTitle, showGreyTitle, ...others } = i;
      const children_by_show = children?.filter(i => !i.hidden);
      if (children_by_show?.length > 0) {
        const formatData = children_by_show?.filter(i => !i.hidden).map(j => {
          const { title, path, key, greyTitle, showGreyTitle, ...others_sub } = j;
          if (greyTitle && showGreyTitle?.((window as any).loginInfo)) {
            return { path, key, label: greyTitle, data: j, };
          }
          return { path, key, label: title, data: j, };
        })
        if (greyTitle && showGreyTitle?.((window as any).loginInfo)) {
          return { children: formatData, path, key, label: greyTitle, icon: icon?.() }
        }
        return { children: formatData, path, key, label: title, icon: icon?.() }
      } else {
        if (greyTitle && showGreyTitle((window as any).loginInfo)) {
          return { path, key, label: greyTitle, data: { ...i, icon: icon?.() } }
        }
        return { path, key, label: title, data: { ...i, icon: icon?.() } }
      }
    })
    return dd;
  }
  // 过滤权限菜单相关
  const filterTreeByAuthCode = (tree, role) => {
    return tree
      .map((node) => {
        // 复制当前节点以避免修改原数据
        const newNode = { ...node };

        // 检查当前节点是否符合条件, gery不存在，或则grey返回true
        if ((newNode.authCode?.includes(role) || newNode.authCode?.length === 0) && (!newNode?.grey || newNode?.grey?.((window as any).loginInfo))) {
          // 如果当前节点符合条件，递归过滤子节点
          newNode.children = newNode.children ? filterTreeByAuthCode(newNode.children, role) : [];
          console.log('newNode', newNode)
          return newNode;
        } else {
          // 如果当前节点不符合条件且有子节点，递归过滤子节点看是否需要保留
          if (newNode.children) {
            const filteredChildren = filterTreeByAuthCode(newNode.children, role);
            // 如果过滤后的子节点有内容，则保留当前节点（作为父节点）
            return filteredChildren.length > 0 ? { ...newNode, children: filteredChildren } : null;
          } else {
            // 当前节点及其子节点都不符合，直接舍弃
            return null;
          }
        }
      })
      .filter(Boolean); // 移除null值，即不符合条件的节点
  };
  const login = () => {
    return vmsService
      .loginOrRegist()
      .then((data: any) => {
        const cur_path = location.pathname;
        if (!data?.success && (data?.msgCode == 'MOZI_NOT_LOGIN' || data?.msgCode == 'MOZI_ACCOUNT_DELETED')) {
          setAccount({});
          //没登录的时候跳home页面
          if (cur_path !== '/') {
            window.location.href = '/';
          }
        } else {
          const res = data.result;
          setAccount(res);
          (window as any).loginInfo = res;
          (window as any).isNewBp = res?.openNewBp;
          (window as any).advertiserType = res.type;
          (window as any).advertiserId = res.advertiserId;
          const { role } = res;
          let real_menu;
          if (!subViewAdvertiserId && !data.result.allShow) {
            if (location.pathname !== '/accountInfo') {
              window.location.href = '/accountInfo';//'/v2/9f7f3bea';
              return;
            }

            real_menu = filterTreeByAuthCode(menus, 'newaccount');
          } else {
            real_menu = filterTreeByAuthCode(menus, role);
          }
          setMenus(real_menu);
          if (role == 'sub') {
            // 子客： home+弹窗（该账号为子账号，没有相关权限，请使用代理商账号登录）
            if (cur_path !== '/') {
              window.location.href = '/';
            }
          } else if (role == 'agent') {
            // 代理商
            (window as any).xrspermission = 'action';
            if (cur_path == '/') {
              window.location.href = '/index';
            }
          } else if (role == 'worker_optimizer') {
            // 优化师
            (window as any).xrspermission = 'action';
            if (cur_path == '/') {
              window.location.href = '/index';
            }
          } else if (role == 'worker_saler') {
            // 销售
            (window as any).xrspermission = 'look';
            if (cur_path == '/') {
              window.location.href = '/index';
            }
          } else if (role == 'worker_finance') {
            // 财务
            (window as any).xrspermission = 'action';
            if (cur_path == '/') {
              window.location.href = '/3cb45b5a';
            }
          } else if (role == 'worker_other') {
            // 其他协作者
            (window as any).xrspermission = 'look';
            if (cur_path == '/') {
              window.location.href = '/index';
            }
          }
        }
      })
      .catch((e: any) => {
        setAccount({});
      });
  }

  useEffect(() => {
    if (
      Object.prototype.toString.call((window as any).WEICONFIG) ===
      "[object Array]"
    ) {
      (window as any).WEICONFIG = {
        config: (window as any).WEICONFIG,
        loading: true,
      };
    }
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('ABF_STORAGE');
    if (location.pathname != '/register') {
      login().then((res: any) => {
        if (!res) {
          const intervalId = setInterval(login, 60 * 60 * 1000);
        }
      });
    } else {
      // setShouldRender(true);
      // setHideTopNav(true);
      micorInit();
    }
  }, []);
  useEffect(() => {
    if (account?.role == 'sub') {
      setVisible(true);
    }
  }, [account?.role]);
  useEffect(() => {
    // 初始化SDK
    if (account?.moziId && adsAEM.current) {
      adsAEM.current?.setConfig({
        uid: account?.moziId,
        username: account?.advertiserName,
        advertiserId: account?.advertiserId,
      })
    }
  }, [account?.moziId]);
  const hideErrorPop = () => {
    setVisible(false);
  }
  const jumpFirstSub = (curItem) => {
    if (curItem?.children) {
      const firstLink = curItem?.children?.[0]?.link;
      if (firstLink) {
        setCurrentTopKey(curItem.key);
        onTopClick(curItem?.children?.[0], curItem?.children)
      } else {
        for (let i = 0; i < curItem?.children?.length; i++) {
          const children = curItem?.children?.[i]?.children.filter(i=>!i.isBlank);
          if (children) {
            const firstLink = children?.[0]?.link;
            if (firstLink) {
              setCurrentTopKey(curItem.key);
              onTopClick(children?.[0], curItem?.children)
              break
            }
          }
        }
        // const subs = curItem?.children?.[0]?.children;
        // const find = curItem?.children?.[0]?.children?.filter(i => i.link);
        // if (find?.length > 0) {
        //   setCurrentTopKey(curItem.key);
        //   onTopClick(find?.[0], curItem?.children)
        // }
      }
    }
  }
  useEffect(() => {
    let sideMenuData = [];
    if (account?.role) {
      sideMenuData = getSideMenu(filterTreeByAuthCode(subMenuList, account?.role));
    } else {
      sideMenuData = getSideMenu(subMenuList);
    }

    setRealSideMenu(sideMenuData)
    setSideOpenKeys(sideMenuData?.map((i: any) => i.key))
  }, [subMenuList, account?.role]);

  return (
    <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]} >
      <ConfigProvider
        //     getPopupContainer={(el) => document.getElementById('app') as any || el}
        theme={{
          hashed: false,
          components: {
            Layout: {
              headerBg: 'transparent'
              /* 这里是你的组件 token */
            },
          },
        }}
      >
        {
          cur_path == '/' || cur_path == '/register' ? (
            <div>
              <Layout style={{ background: 'transparent' }}>
                <Content>
                  <div id={containorId}></div>
                </Content>
              </Layout>
              <Modal open={visible} visible={visible} footer={null} onCancel={hideErrorPop} closable={true} className='crmLoginErrorPop' centered>
                <div className={styles.subpop}>
                  <div className={styles.subpoptxt}>
                    <InfoCircleOutlined />&nbsp;&nbsp;该账号为子账号，没有相关权限，请使用代理商账号登录
                  </div>
                </div>
              </Modal>
            </div>) : <div id='dspV3'>
            <Layout className={styles.ct} style={{ background: 'none' }}>
              <Header className={styles.header}>
                <div className={styles.header_inner}>
                  <img src='https://img.alicdn.com/imgextra/i1/O1CN01Ra093T1GFBQlmcG0m_!!6000000000592-2-tps-155-57.png' width='90' />
                  <Divider type="vertical" style={{ marginTop: 19 }} />
                  <Text style={{ fontWeight: 600, marginTop: 16, fontSize: 20 }}>CRM</Text>
                </div>

                <Menu
                  theme="light"
                  mode="horizontal"
                  style={{ border: "0", background: 'none', flex: 1, height: 53, lineHeight: '53px' }}
                  selectedKeys={[currentTopKey]}
                  id='J_CrmTopMenu'
                //当前选中的菜单项 key 数组
                >
                  {menus.map((item: any) => {
                    return item.children?.length > 0 ? (
                      <Menu.Item
                        key={item.key}
                        onClick={() => {
                          jumpFirstSub(item);
                          //  topMenuClick(item);
                        }}
                      >
                        <Popover
                          arrow={false}
                          content={() => {
                            return (
                              <div style={{ background: '#fff' }}>
                                {renderMenuOnPop(item.children, 'strong', item)}
                              </div>
                            );
                          }}
                        >
                          <div>
                            <span style={{ fontSize: 16, fontWeight: 500 }}>{item.title}</span>
                            <CaretDownOutlined style={{ marginLeft: 4, fontSize: 12, color: '#bfbfbf' }} />
                          </div>
                        </Popover>
                      </Menu.Item>
                    ) : <Menu.Item
                      key={item.key}
                      onClick={() => {
                        setCurrentTopKey(item.key);
                        topMenuClick(item);
                      }}
                    >
                      <span style={{ fontSize: 16, fontWeight: 500 }}>{item.title}</span>
                    </Menu.Item>
                  })
                  }
                </Menu>
                <LoginMod loginInfo={account} />
              </Header>
              <Layout style={{ padding: 16, background: 'transparent', minWidth: 1408 }}>
                {realSideMenu && realSideMenu.length > 0 && (
                  <Sider collapsedWidth={0} width={180} className={styles.sidebar}>
                    <Menu
                      className='crm-sidemenu'
                      inlineIndent={6}
                      items={realSideMenu}
                      mode="inline"
                      onOpenChange={(openKeys) => {
                        setSideOpenKeys(openKeys)
                      }}
                      openKeys={sideOpenKeys}
                      selectedKeys={[currentkey]}
                      style={{ background: '#DFEAFB', borderRadius: 8, overflow: 'hidden', border: 'none' }}
                      onClick={({ item }) => {
                        leftMenuClick((item as any)?.props?.data)
                      }}
                    />
                  </Sider>
                )}
                <Layout style={{ background: 'transparent' }}>
                  <Content
                    className={styles.content}
                    style={location.pathname == '/index' ? { padding: 0 } : { padding: '0px 24px' }}
                  >
                    <div id={containorId}></div>
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          </div>

        }
      </ConfigProvider>
    </StyleProvider>
  )
}


export default AdsLayout;